<template>
  <div>
    <el-table :size="size" :border="true" :data="tableData">
      <el-table-column align="center" prop="userName" label="学员姓名" />
      <el-table-column align="center" prop="flag" label="消费类型" />
      <el-table-column align="center" prop="amount" label="数量（课时）">
        <template slot-scope="scope">
          <span
            v-if="scope.row.amount!=0"
            :style="{
              fontWeight: 'bold',
              color: scope.row.flag == '报名' ? 'green' : 'red',
            }"
          >
            {{ scope.row.flag == "报名" ? "+" : "-" }}
          </span>
          <span>
            {{ scope.row.amount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="courseName" label="课程">
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.courseName" placement="top">
            <span class='text-oneline'>
              {{ scope.row.courseName }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="courseGoodsName" label="课程账号类型" />
      <el-table-column align="center" sortable prop="createTime" label="时间" width="150"/>
    </el-table>
    <el-pagination
      :size="size"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.pageNum"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      layout="total, sizes, prev, pager, next, jumper"
      background
    >
    </el-pagination>
  </div>
</template>
<script>
import StudentCourseLogList from "./StudentCourseLogList";
import { getStudentCourseLogList } from "@/api/businesscourse/course";
export default {
  components: { StudentCourseLogList },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
      }),
    },
  },
  data() {
    return {
      drawer: false,
      direction: "btt",
      size: "small",
      tableData:[],
      pageInfo:{
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      }
    };
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    initData(){
      this.pageInfo={
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      };
      this.$nextTick(()=>{
        this.getListHandler();
      })
    },
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getStudentCourseLogList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
</style>
