<template>
  <div class="outer-style">
    <el-card shadow="never">
      <el-form :inline="true" :model="searchForm">
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.userName"
            placeholder="学员姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.courseName"
            placeholder="课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="searchForm.courseGoodsName"
            placeholder="课程账号类型"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="searchForm.flag" placeholder="消费类型">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getListHandlers">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <StudentCourseLogList ref="detail" :searchForm="searchForm"/>
    </el-card>
  </div>
</template>
<script>
import StudentCourseLogList from './components/StudentCourseLogLists'
export default {
  components:{StudentCourseLogList},
  data() {
    return {
      size: "small",
      searchForm: {
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
      },
      statusOptions: {
        报名: "报名",
        退课: "退课",
      },
      options: [
        {
          value: "报名",
          label: "报名",
        },

        {
          value: "退课",
          label: "退课",
        },
      ],
    };
  },
  mounted() {
    this.getListHandlers();
  },
  methods: {
    getListHandlers(){
      this.$refs.detail.initData();
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.searchForm = {
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
      };
      this.getListHandlers();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>
